<template>
  <div id="app">
    <head-bar></head-bar>
    <router-view class="main-page-wrap" />
  </div>
</template>

<script>
import HeadBar from '@/components/HeadBar.vue';

export default {
  components: {
    HeadBar,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="less">
@import './assets/styles/common.less';
@import './assets/font/font.css';
@import './assets/styles/reset.css';
</style>
