import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/index'),
  },
  {
    path: '/mask/hot',
    name: 'Hot',
    component: () => import('../views/mask/hot'),
  },
  {
    path: '/mask/full',
    name: 'Full',
    component: () => import('../views/mask/full'),
  },
  {
    path: '/mask/steel',
    name: 'Steel',
    component: () => import('../views/mask/steel'),
  },
  {
    path: '/mask/pack',
    name: 'Pack',
    component: () => import('../views/mask/pack'),
  },
  {
    path: '/authCode',
    name: 'AuthCode',
    component: () =>
      import(/* webpackChunkName: "AuthCode" */ '../views/authCode/index'),
  },
  {
    path: '/order',
    name: 'Order',
    component: () =>
      import(/* webpackChunkName: "Order" */ '../views/order/index'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
