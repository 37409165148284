import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuesax from 'vuesax';
import ElementUI from 'element-ui';
import VueLazyload from 'vue-lazyload';

import 'element-ui/lib/theme-chalk/index.css'; // element-ui styles
import 'element-ui/lib/theme-chalk/base.css';
import 'vuesax/dist/vuesax.css'; //Vuesax styles

Vue.config.productionTip = false;

Vue.use(VueLazyload, {
  loading: require('./assets/images/two-ball-loading.gif'), //加载中图片，一定要有，不然会一直重复加载占位图
  error: require('./assets/images/two-ball-loading.gif'), //加载失败图片
});
Vue.use(Vuesax);
Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
