<template>
  <div class="header-wrap">
    <div class="header-content">
      <div @click="toHomePage" class="header-logo-wrap">
        <img
          style="width: 64px; height: 60px;"
          src="@/assets/images/logo.png"
          alt=""
        />
        <span class="web-font">
          {{ getTitle || '可美口罩' }}
        </span>
      </div>

      <i @click.stop="openMenu()" class="h5-menu-btn el-icon-menu"></i>

      <div class="link-wrap">
        <template v-for="(item, index) in menuList">
          <el-divider
            :key="'divider_' + index"
            v-if="item.divider"
            direction="vertical"
          ></el-divider>
          <template v-else-if="item.path === '/order'">
            <el-popover
              class="ml20"
              :key="'order_' + index"
              placement="bottom"
              width="200"
              trigger="hover"
            >
              <div style="text-align: center;">
                <div style="padding: 5px; color: #848484;">
                  「手机扫码查询」
                </div>
                <img
                  height="130"
                  style="border-radius: 8px;"
                  src="@/assets/images/order-code.png"
                  alt=""
                />
                <!-- <a href="/order" target="_blank">
                  <el-button
                    type="text"
                    style="padding: 5px;"
                    icon="el-icon-link"
                    >继续访问PC端</el-button
                  >
                </a> -->
              </div>

              <el-link
                slot="reference"
                :underline="true"
                :type="$route.path === '/order' ? 'primary' : 'default'"
                href="/order"
              >
                {{ item.menu }}
              </el-link>
            </el-popover>
          </template>
          <el-link
            v-else
            :key="'link_' + index"
            :underline="true"
            :type="$route.path === item.path ? 'primary' : 'default'"
            :href="item.path"
          >
            {{ item.menu }}
          </el-link>
        </template>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :fullscreen="true"
      :destroy-on-close="true"
      :body-style="{ padding: '0px' }"
    >
      <el-menu>
        <el-menu-item
          v-for="(item, index) in filterMenuList"
          :key="index"
          :class="{ active: $route.path === item.path }"
          @click="jumpToPage(item)"
        >
          <span slot="title">{{ item.menu }}</span>
        </el-menu-item>
      </el-menu>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      menuList: [
        {
          menu: '首页',
          path: '/',
        },
        {
          divider: true,
        },
        {
          menu: '烫印口罩',
          path: '/mask/hot',
        },
        {
          menu: '满印口罩',
          path: '/mask/full',
        },
        {
          menu: '钢印口罩',
          path: '/mask/steel',
        },
        {
          menu: '包装定制',
          path: '/mask/pack',
        },
        {
          divider: true,
        },
        {
          menu: '公司简介',
          path: '/about',
        },
        {
          menu: '订单跟踪',
          path: '/order',
        },
      ],
    };
  },
  methods: {
    toHomePage() {
      this.$router.push('/');
    },
    openMenu() {
      this.dialogVisible = true;
    },
    jumpToPage(item) {
      this.dialogVisible = false;
      this.$router.push(item.path);
    },
  },
  computed: {
    filterMenuList() {
      return this.menuList.filter((item) => !item.divider);
    },
    getTitle() {
      let rname = this.$route.name;
      if (!rname) return '';
      let title = '可美口罩';
      if (rname === 'Home') {
        title += '定制设计平台';
      }
      return title;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0;
  padding-top: 20px;
}

/deep/ .el-menu-item {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
  &.active {
    color: #409eff;
  }
}

.header-wrap {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9;
  background: #fff;
  color: #1f2329;
  font-size: 14px;

  .header-content {
    max-width: @mainContainerWidth;
    height: 80px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .header-logo-wrap {
      &:hover {
        cursor: pointer;
      }
      .all-center;
      .web-font {
        display: inline-block;
        color: #1f2329;
        font-size: 20px;
        font-weight: bold;
        margin-left: 26px;
        font-family: 手刻宋;
        height: 60px;
        line-height: 60px;
      }
    }
  }
}

.h5-menu-btn {
  display: none;
}

/deep/ .link-wrap {
  .el-link + .el-link {
    margin-left: 20px;
  }

  .el-divider--vertical {
    margin: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .header-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .link-wrap {
    display: none;
  }
  .web-font {
    position: absolute;
    margin-left: 0 !important;
    left: 80px !important;
    right: 80px !important;
    top: 0 !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .h5-menu-btn {
    display: block;
    font-size: 28px;
    color: #666;
  }
}
</style>
